// UI框架
import React, { Component } from 'react';
// UI组件
import { Row, Col, Form, Empty, Table, Switch, Popconfirm, Checkbox, Divider, Select } from 'antd';
import { LabelInput, LabelSelect, LabelSlider, LabelSwitch } from 'libs';
// 业务组件
import { bindLifecycle } from 'utils/bindLifecycle';
import { LangConsumer } from 'utils/LangProvider';
import { clone, transToChannelName } from '../../../../common';
import _ from 'lodash';
import WebApp from '../../../../webapp';
import {lengthB} from 'utils/checkbit';
// 样式
import './index.less';

const _name_ = 'TrafficIllegalRuleConfigurationConfigForm';
const sliderMarks = {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10'
};

class TrafficIllegalRuleConfigurationConfigFormComponent extends React.Component {
    /** 用来存放组件内状态 */
    constructor (props) {
        super(props);
        this.ComponentName = _name_;
        this.state = {};
    }

    componentDidMount () {}

    componentDidActivate () {}

    componentWillUnmount () {}

    render () {
        let {$t, form, ruleConfig, ruleType, colWidth, lang, supportSnapShot, fogLevelOptions, supportLinkage} = this.props;
        const { getFieldDecorator } = form;
        return (
            <div className={`_${_name_}_`}>
                <Form labelAlign={'left'}>
                    {supportSnapShot &&
                        <Form.Item>
                            {getFieldDecorator('Zoom', {
                                valuePropName: 'checked'
                            })(
                                <LabelSwitch
                                    label={$t('ivs.Zoom')}
                                    colWidth = {colWidth}
                                    behind={!!supportLinkage && ruleConfig && ruleConfig.Config && ruleConfig.Config.ZoomEnable ?
                                        <Form.Item>
                                            {getFieldDecorator('LinkageChannels', {
                                            })(
                                                <Select>
                                                    {supportLinkage.map(item => (
                                                        <Select.Option value={item.Channel} key={item.Channel} title={$t('ivs.LinkTo').replace('%s', transToChannelName($t, item.Channel - 0))}>{$t('ivs.LinkTo').replace('%s', transToChannelName($t, item.Channel - 0))}</Select.Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item> : null}
                                />
                            )}
                        </Form.Item>
                    }
                    {ruleConfig && ruleConfig.Config && ruleConfig.Config.Sensitivity !== undefined &&
                        <Form.Item>
                            {getFieldDecorator('Sensitivity', {
                            })(
                                <LabelSlider min={0} max={10} marks={sliderMarks} label={$t('com.Sensitivity')} colWidth = {colWidth} />
                            )}
                        </Form.Item>
                    }
                    {ruleType !== 'TrafficAccident' && ruleType !== 'TrafficThrow' &&
                        <Form.Item>
                            {getFieldDecorator('TriggerDuration', {
                            })(
                                <LabelInput
                                    type='number'
                                    label={$t('ivs.MinDuration')}
                                    colWidth = {colWidth}
                                    max={300}
                                    min={5}
                                    precision={0}
                                    behind={<span>{$t('com.Second')}(5-300)</span>}
                                />
                            )}
                        </Form.Item>
                    }
                    {ruleType === 'TrafficThrow' &&
                    <Form.Item>
                        {getFieldDecorator('Delay', {
                        })(
                            <LabelInput
                                type='number'
                                min={0}
                                max={3600}
                                precision={0}
                                label={$t('com.AlarmTime')}
                                colWidth={colWidth}
                                behind={<span>{`${$t('com.Second')} (0-3600)`}</span>}
                            />
                        )}
                    </Form.Item>}
                    {ruleType !== 'TrafficThrow' &&
                    <Form.Item>
                        {getFieldDecorator('AlarmFilterDuration', {
                        })(
                            <LabelInput
                                type='number'
                                label={$t('ivs.CaptureUploadingValue')}
                                colWidth = {colWidth}
                                max={1440}
                                min={0}
                                precision={0}
                                behind={<span>{$t('com.Minute')}(0-1440)</span>}
                            />
                        )}
                    </Form.Item>}
                    {ruleType === 'FogDetection' &&
                        <Form.Item>
                            {getFieldDecorator('AlarmFogLevel', {
                            })(
                                <LabelSelect
                                    label={$t('ivs.AlarmThreshold')}
                                    colWidth = {colWidth}
                                >
                                    {fogLevelOptions.map((item, index) => {
                                        return <Select.Option key={index} value={item.value}>{$t(item.text)}</Select.Option>;
                                    })}
                                </LabelSelect>
                            )}
                        </Form.Item>
                    }
                    {ruleType === 'TrafficAccident' &&
                        <Form.Item>
                            {getFieldDecorator('VehicleTriggerTime', {
                            })(
                                <LabelInput
                                    type='number'
                                    label={$t('ivs.VehicleTriggerTime')}
                                    colWidth = {colWidth}
                                    max={3600}
                                    min={0}
                                    precision={0}
                                    behind={<span>{$t('com.Second')}(0-3600)</span>}
                                />
                            )}
                        </Form.Item>
                    }
                    {ruleType === 'TrafficAccident' &&
                        <Form.Item>
                            {getFieldDecorator('PersonTriggerTime', {
                            })(
                                <LabelInput
                                    type='number'
                                    label={$t('ivs.PersonTriggerTime')}
                                    colWidth = {colWidth}
                                    max={3600}
                                    min={0}
                                    precision={0}
                                    behind={<span>{$t('com.Second')}(0-3600)</span>}
                                />
                            )}
                        </Form.Item>
                    }
                    {ruleType === 'TrafficAccident' &&
                        <Form.Item>
                            {getFieldDecorator('ZoomDuration', {
                            })(
                                <LabelInput
                                    type='number'
                                    label={$t('ivs.ZoomDuration')}
                                    colWidth = {colWidth}
                                    max={1200}
                                    min={0}
                                    precision={0}
                                    behind={<span>{$t('com.Second')}(0-1200)</span>}
                                />
                            )}
                        </Form.Item>
                    }
                    <Form.Item label={$t('ivs.VideoParameterSD')} labelCol={{span:6}} wrapperCol={{span:12}}>
                        {getFieldDecorator('VideoParameters', {
                        })(
                            <Checkbox.Group>
                                <Checkbox value={0}>{$t('ivs.LocalSave')}</Checkbox>
                                <Checkbox value={1}>{$t('com.FTPUpload')}</Checkbox>
                            </Checkbox.Group>
                        )}
                    </Form.Item>
                </Form>
                <Divider />
            </div>
        );
    }
}

let ruleForm = Form.create(
    {
        name: 'TrafficIllegalRuleConfigurationConfigForm',
        mapPropsToFields (props) {
            let {ruleConfig, recordConfig, snapShotConfig, globalConfig, calibrateConfig, $t, ruleType} = props;
            return {
                Zoom: Form.createFormField({
                    value: ruleConfig && ruleConfig.Config && ruleConfig.Config.ZoomEnable
                }),
                Sensitivity: Form.createFormField({
                    value: ruleConfig && ruleConfig.Config && (ruleType === 'TrafficThrow' ? ruleConfig.Config.Sensitivity / 10 : ruleConfig.Config.Sensitivity)
                }),
                TriggerDuration: Form.createFormField({
                    value: snapShotConfig && snapShotConfig.SingleInterval instanceof Array && snapShotConfig.SingleInterval[0]
                }),
                AlarmFilterDuration: Form.createFormField({
                    value: snapShotConfig && snapShotConfig.RepeatInterval
                }),
                VehicleTriggerTime: Form.createFormField({
                    value: ruleConfig && ruleConfig.Config && ruleConfig.Config.VehicleDelayTime
                }),
                PersonTriggerTime: Form.createFormField({
                    value: ruleConfig && ruleConfig.Config && ruleConfig.Config.PersonDelayTime
                }),
                ZoomDuration: Form.createFormField({
                    value: ruleConfig && ruleConfig.Config && ruleConfig.Config.ZoomStayTime
                }),
                VideoParameters: Form.createFormField({
                    value: [recordConfig && recordConfig.Enable && 0, recordConfig && recordConfig.UploadRecordEnable && 1]
                }),
                AlarmFogLevel: Form.createFormField({
                    value: ruleConfig && ruleConfig.Config && ruleConfig.Config.AlarmFogLevel
                }),
                Delay: Form.createFormField({
                    value: ruleConfig && ruleConfig.Config && ruleConfig.Config.DelayTime
                }),
                LinkageChannels: Form.createFormField({
                    value: ruleConfig && ruleConfig.LinkClassType && ruleConfig.LinkClassType[0] && ruleConfig.LinkClassType[0].Channel //业务通道间互斥，
                }),
            };
        },
        onFieldsChange (props, changedFields) {
            props.formChange(changedFields);
        }
    }
);

export default LangConsumer(bindLifecycle(ruleForm(TrafficIllegalRuleConfigurationConfigFormComponent)));